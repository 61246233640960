import React from "react";
import { Link, useLocation } from "react-router-dom";

const MenuHorizontalCellView = ({ item }) => {
  const location = useLocation();
  const itemContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "15px 15px",
    justifyContent: "flex-start",
  };

  const menuItemImageStyle = {
    width: "120px",
    height: "120px",
    borderRadius: "8px",
    alignSelf: "center",
    margin: "0px",
    objectFit: "cover",
  };

  const textStyle = {
    fontSize: "15px",
    fontWeight: "450",
    textAlign: "left",
    margin: "0px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    lineClamp: "2",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
  };

  const descriptionStyle = {
    fontSize: "12px",
    fontWeight: "400",
    color: "gray",
    flexGrow: "0",
    textAlign: "left",
    margin: "0px",
  };

  const menuItemDescriptionStyle = {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "110px",
    padding: "10px 20px 10px 0px",
    boxSizing: "border-box",
    margin: "0px",
  };

  const menuItemPriceStyle = {
    fontSize: "12px",
    fontWeight: "500",
    flexAlign: "bottom",
    textAlign: "left",
    flexGrow: "1",
    margin: "0px",
    paddingTop: "5px",
  };

  return (
    <Link className="plainLink" to={`${location.pathname}/item/${item.id}`}>
    <div style={itemContainerStyle}>
      <div style={menuItemDescriptionStyle}>
        <p style={textStyle}>{item.name}</p>
        <p className="truncate-text" style={descriptionStyle}>{item.description}</p>
      <p style={menuItemPriceStyle}>{`R ${item.price}`}</p>
      </div>
      {item.imageUrl != null &&
      <img src={item.imageUrl} style={menuItemImageStyle} alt="menuitem" />}
    </div>
    </Link>
  );
};

export default MenuHorizontalCellView;