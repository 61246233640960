import { SET_MENU_ITEMS, SET_RESTAURANT, SET_RESTAURANT_CLOSE_TIME, SET_RESTAURANT_DISTANCE, SET_RESTAURANT_ID, SET_RESTAURANT_LOADING, SET_RESTAURANT_OPEN } from "../Actions/restaurantActions";

const initialState = {
    restaurantID: "",
    restaurant: {},
    restaurantLoading: false,
    restaurantOpen: false,
    restaurantCloseTime: null,
    distanceInt: null,
    distanceString: null,
    menu: {},
};

const restaurantReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RESTAURANT_ID:
            return {
                ...state,
                restaurantID: action.payload,
            };
            case SET_RESTAURANT:
            return {
                ...state,
                restaurant: action.payload,
                restaurantID: action.payload.id || "",
            };
            case SET_RESTAURANT_LOADING:
            return {
                ...state,
                restaurantLoading: action.payload,
            };
            case SET_RESTAURANT_DISTANCE:
                return {
                    ...state,
                    distanceInt: action.payload.distanceInt,
                    distanceString: action.payload.distanceString,
                };
            case SET_MENU_ITEMS:
                return {
                    ...state,
                    menu: action.payload,
                };
            case SET_RESTAURANT_OPEN:
                return {
                    ...state,
                    restaurantOpen: action.payload,
                };
            case SET_RESTAURANT_CLOSE_TIME:
                return {
                    ...state,
                    restaurantCloseTime: action.payload,
                };
        default:
             return state
    }
};

export default restaurantReducer