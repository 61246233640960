import React, { useEffect, useRef } from "react";
import MenuCategoryButton from "./MenuCategoryButton";


const MenuCategoryScroll = ({ categories, selectedCategory, handleCategoryClick }) => {
    const categoryRefs = useRef(categories.map(() => React.createRef()));
    useEffect(() => {
        const index = categories.indexOf(selectedCategory);
        if (categoryRefs.current[index]) {
            const container = document.querySelector('.categoryButtons');
        const buttonRect = categoryRefs.current[index].current.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Calculate new scroll position
        const newScrollPosition = container.scrollLeft + (buttonRect.left - containerRect.left) - 15;

        // Scroll to new position
        container.scrollTo({
            left: newScrollPosition,
            behavior: 'smooth'
        });
            }   
            // eslint-disable-next-line
      }, [selectedCategory]);
  return (
    <div className="categoryButtons">
            {categories.map((category, index) => (
                <MenuCategoryButton 
                ref={categoryRefs.current[index]}
                key={index}
                text={category} 
                selected={selectedCategory === category}
                onClick={() => handleCategoryClick(category, index)} />
            ))}
          </div> 
  );
};

export default MenuCategoryScroll;