import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import MenuMain from './Views/Menu/MenuMain';
import AuthMain from './Views/Auth/AuthMain';
import CheckoutTest from './Views/Cart/CheckoutTest';
import CreateAddress from './Views/Helpers/CreateAddress';
import ReviewOrder from './Views/Cart/ReviewOrder';
import CountryCodeSelector from './Views/Auth/CountryCodeSelector';
import CodeVerificationScreen from './Views/Auth/CodeVerificationScreen';
import  { AuthProvider } from './Helpers/Contexts/AuthProvider';
import MenuItemDetailView from './Views/Menu/MenuItem/MenuItemDetailView';
import CheckoutMain from './Views/Cart/CheckoutMain';
function App() {
  return (
    <div>
      <BrowserRouter>
      <AuthProvider>
      <Routes>
        <Route path="/:venueName" element={<MenuMain />} />
        <Route path="/:venueName/item/:itemID" element={<MenuItemDetailView />} />
        <Route path="/checkout" element={<CheckoutMain />} />
        <Route path="/revieworder" element={<ReviewOrder />} />
        <Route path="/pay" element={<CheckoutTest />} />
        <Route path="/address" element={<CreateAddress />} />
        <Route path="/auth" element={<AuthMain />} />
        <Route path="/verify" element={<CodeVerificationScreen />} />
        <Route path="/countrycode" element={<CountryCodeSelector />} />
      </Routes>
      </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
