export const SET_VENUE = "SET_VENUE";
export const SET_VENUE_ID = "SET_VENUE_ID";
export const SET_VENUE_LOADING = "SET_VENUE_LOADING";
export const SET_VENUE_DISTANCE = "SET_VENUE_DISTANCE";
export const SET_MENU_ITEMS = "SET_MENU_ITEMS";
export const SET_VENUE_OPEN = "SET_VENUE_OPEN";
export const SET_VENUE_CLOSE_TIME = "SET_VENUE_CLOSE_TIME";

const setVenue = (venue) => ({
        type: SET_VENUE,
        payload: venue,
});

const setVenueID = (venueID) => ({
    type: SET_VENUE_ID,
    payload: venueID,
});

const setVenueLoading = (bool) => ({
    type: SET_VENUE_LOADING,
    payload: bool,
});

const setVenueDistance = (distance) => ({
    type: SET_VENUE_DISTANCE,
    payload: distance,
});

const setMenuItems = (menu) => ({
    type: SET_MENU_ITEMS,
    payload: menu,
});

const setVenueOpen = (bool) => ({
    type: SET_VENUE_OPEN,
    payload: bool,
});

const setVenueCloseTime = (time) => ({
    type: SET_VENUE_CLOSE_TIME,
    payload: time,
});


export { setVenue, setVenueID, setVenueLoading, setVenueDistance, setMenuItems, setVenueOpen, setVenueCloseTime };