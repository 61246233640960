export const CHANGE_ADDRESS = "CHANGE_ADDRESS";
export const SET_USER = "SET_USER";
export const SET_USER_SIGNED_IN = "SET_USER_SIGNED_IN";
export const SET_GRID_LAYOUT = "SET_GRID_LAYOUT"
export const SET_IS_DELIVERY = "SET_IS_DELIVERY"
export const SET_COUNTRY_CODE = "SET_COUNTRY_CODE" 


const changeAddress = (address) => ({
        type: CHANGE_ADDRESS,
        payload: address,
});

const setUser = (user) => {
        const { dateJoined, ...restOfUser } = user;
        return {
        type: SET_USER,
        payload: restOfUser,
}};

const setUserSignedIn = (bool) => ({
        type: SET_USER_SIGNED_IN,
        payload: bool,
});

const setGridLayout = (bool) => ({
        type: SET_GRID_LAYOUT,
        payload: bool,
});
const setIsDelivery = (bool) => ({
        type: SET_IS_DELIVERY,
        payload: bool,
});
const setCountryCode = (code) => ({
        type: SET_COUNTRY_CODE,
        payload: code,
});

export { changeAddress, setUser, setGridLayout, setIsDelivery, setCountryCode, setUserSignedIn };