import React, { useState } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51NQlvJJUTIEvfN0jZxaDiptzEoRJUw0PyfivWJBzk2MBx6FT9xxlhUAcqlssbcrMXqbMPeOIaCnYRfXk78ZLr4IL00PkkTVWbw');

const CheckoutTest = () => {
    const options = {
        clientSecret: "pi_3NyCgNJUTIEvfN0j1luiGWdt_secret_nCMcPorCUlRVpfkYzBahaJzzd",
      };

  return (
      <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
      </Elements>
  );
};

export default CheckoutTest;

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
  
    const [errorMessage, setErrorMessage] = useState(null);
  
    const handleSubmit = async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      const {error} = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: 'https://order.thecrwd.com/Orexi',
        },
      });
  
  
      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setErrorMessage(error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    };
  
    return (
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button disabled={!stripe}>Submit</button>
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </form>
    )
  };