import React from 'react';
import DeliveryToggle from './DeliveryToggle';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setIsDelivery } from '../../../Redux/Actions/authActions';

function DeliveryPickupCell({ venueAddress }) {
    const state = useSelector(state => state.auth);
    const address = state.selectedAddress;
  const isDelivery = state.isDelivery;
  const dispatch = useDispatch();

  const toggleOption = () => {
    dispatch(setIsDelivery(!isDelivery))
  }
    const navigate = useNavigate();

  const chooseAddress = () => {
    navigate("/address");
  };
      return (
            <div className="hstackMimic" style={{margin: "10px 15px 0px 15px"}}>
        <div onClick={chooseAddress} style={{flexGrow: "1"}}>
            <p style={{textAlign: "left", padding: "0", margin:"0", fontWeight: "500", fontSize: "12px"}}>{isDelivery ? "Delivering To" : "Pickup at"}</p>
            <p style={{textAlign: "left", padding: "0", fontWeight: "400", fontSize: "14px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", margin: "0px 10px 0px 0px", width: "50vw"}} className="secondaryText">{isDelivery ? `${address?.fullAddressString || "Tap to choose address"}` : venueAddress}</p>
            </div>
            <DeliveryToggle isDelivery={isDelivery} toggleOption={toggleOption} />
            </div>
      );
}

export default DeliveryPickupCell;
