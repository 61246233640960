import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const MenuVerticalCellView = ({ item }) => {
    const [dimension, setDimension] = useState(0);
    const location = useLocation();

    useEffect(() => {
        const setDimensions = () => {
            const width = (window.innerWidth - 75) * 0.5; // Adjust this factor based on your layout
            setDimension(width);
        };

        // Initial setting
        setDimensions();

        // Add event listener
        window.addEventListener("resize", setDimensions);

        // Cleanup
        return () => {
            window.removeEventListener("resize", setDimensions);
        };
    }, []);

    const itemContainerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: `${dimension}px`,
        margin: "10px 0px", // Added margin for better spacing
        padding: "0px",
        boxSizing: "border-box",
    };

    const menuItemImageStyle = {
        width: "100%", // Use full width to ensure it scales correctly
        height: `${dimension}px`,
        borderRadius: "8px",
        objectFit: "cover",
    };

    const menuItemDescriptionStyle = {
        width: "100%",
        padding: "10px 0px", // Adjusted padding
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
    };

    const textStyle = {
        fontSize: "15px",
        fontWeight: "450",
        textAlign: "left",
        margin: "0px",
        whiteSpace: "normal", // Allows text to wrap
        wordWrap: "break-word", // Breaks long words
        overflow: "hidden", // To prevent overflow if too long
    };

    const descriptionStyle = {
        fontSize: "12px",
        fontWeight: "400",
        color: "gray",
        textAlign: "left",
        margin: "5px 0px 0px 0px", // Adjusted margin for spacing
        whiteSpace: "normal", // Allows text to wrap
        wordWrap: "break-word", // Breaks long words
        overflow: "hidden", // To prevent overflow if too long
    };

    const menuItemPriceStyle = {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "left",
        marginTop: "auto", // Pushes price to the bottom
        paddingTop: "10px",
    };

    return (
        <Link className="plainLink" to={`${location.pathname}/item/${item.id}`}>
            <div style={itemContainerStyle}>
                {item.imageUrl != null && (
                    <img
                        src={item.imageUrl}
                        style={menuItemImageStyle}
                        alt="menuitem"
                    />
                )}
                <div style={menuItemDescriptionStyle}>
                    <p style={textStyle}>{item.name}</p>
                    <p className="truncate-text" style={descriptionStyle}>{item.description}</p>
                    <p style={menuItemPriceStyle}>{`R ${item.price}`}</p>
                </div>
            </div>
        </Link>
    );
};

export default MenuVerticalCellView;