import React from 'react';

function DeliveryToggle({ isDelivery, toggleOption }) {
  const handleContainerClick = (event) => {
    const containerBounds = event.currentTarget.getBoundingClientRect();
    const clickPosition = event.clientX - containerBounds.left;

    // Check if the click was on the left half (not blue when isDelivery is true)
    if (clickPosition <= containerBounds.width / 2 && !isDelivery) {
      toggleOption();
    }

    // Check if the click was on the right half (not blue when isDelivery is false)
    else if (clickPosition > containerBounds.width / 2 && isDelivery) {
      toggleOption();
    }
  };
  // Conditionally determine the text to be displayed based on isDelivery and screen size.
      return (
        <div className="toggle-container" onClick={handleContainerClick}> 
        <div 
          className="slider" 
          style={{ transform: isDelivery ? 'translateX(0%)' : 'translateX(100%)' }}
        >
        </div>
        <div 
          className={`toggle-option`}
          style={isDelivery ? {color: "white"} : {color: "black"}}
        >
            <span className="material-symbols-rounded" style={{fontSize: "20px", margin: "0", padding: "0 5px 0 5px"}}>
directions_bike
</span>
        </div>
        <div 
          className={`toggle-option`}
          style={!isDelivery ? {color: "white"} : {color: "black"}}
        >
            <span className="material-symbols-rounded" style={{fontSize: "20px", margin: "0", padding: "0 5px 0 5px"}}>
directions_walk
</span>
        </div>
      </div>
      );
}

export default DeliveryToggle;
