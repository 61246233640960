import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MenuCategoryScroll from "./MenuComponents/MenuCategoryScroll";
import MenuCategorySection from "./MenuComponents/MenuCategorySection";
import DeliveryPickupCell from "./MenuComponents/DeliveryPickupCell";
import { useDispatch, useSelector } from "react-redux";
import { setGridLayout } from "../../Redux/Actions/authActions";
import { changeCartRestaurant} from "../../Redux/Actions/cartActions";
import { Helmet } from "react-helmet-async";
import RestaurantService from "../../Services/RestaurantService";

export default function MenuMain(props) { 
    const [selectedCategory, setSelectedCategory] = useState("");
    const [isAutoScrolling, setIsAutoScrolling] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredMenu, setFilteredMenu] = useState({});
    const navigate = useNavigate();
    const venueState = useSelector((state) => state.restaurant);
    const authState = useSelector((state) => state.auth);
    const cartState = useSelector((state) => state.cart);
    const address = authState.selectedAddress;
    const venue = venueState.restaurant;
    const isGrid = authState.isGridLayout;
    const restService = new RestaurantService;
    const dispatch = useDispatch();
    const { venueName } = useParams();
    const venueMenu = venueState.menu;
    const menu = (searchQuery.length > 0) ? filteredMenu : venueMenu;
    const categories = Object.keys(venueMenu)
    const categoryRefs = useRef([]);
    const menuStickyHeaderRef = useRef(null);
    const handleSearch = (event) => {
      setSearchQuery(event.target.value)

      if (searchQuery.length > 0) {
        // Make sure the element exists before trying to scroll it
        if (menuStickyHeaderRef.current) {
          menuStickyHeaderRef.current.scrollIntoView({
            behavior: "smooth", // smooth scroll
            block: "start", // align to the top
          });
        }
      }
    }

    useEffect(() => {
      setFilteredMenu(searchMenu(venueMenu, searchQuery))
    }, [searchQuery]);

    useEffect(() => {
      categoryRefs.current = categoryRefs.current.slice(0, categories.length);
      // Create refs for new categories
      while (categoryRefs.current.length < categories.length) {
        categoryRefs.current.push(React.createRef());
      }
    }, [categories]);
    

      useEffect(() => {
    
        if (venueName !== venueState.restaurant.urlPath)
        restService.fetchRestaurant(venueName, dispatch)

        if (window !== window.top) {
          if (venue.customBranding) {
           // document.documentElement.style.setProperty('--accent-color', venue.accentColor)
          }
          console.log("App is running inside an iframe.");
      } else {
          // The app is being loaded directly in the browser
          console.log("App is running directly in the browser.");
      }
      }, [venueName]);

      useEffect(() => {
        // Check if venue.id and address are not null
        if (venue.id != null && address != null) {
          // If both are not null, then check the distance
          restService.checkDistance(address.latitude, address.longitude, venue.location.latitude, venue.location.longitude, dispatch);
        }
        
        // Check if cartState.venueID is not equal to venue.id
        if (cartState.restaurantID !== venueState.restaurantID) {
          // Check if cartState.items is null
          if (cartState.items == []) {
            // If cartState.items is null, dispatch changeCartVenue action
            dispatch(changeCartRestaurant(venueState.restaurant));
          }
        }
      }, [venue, address]);

      useEffect(() => {
        const stickyHeader = document.querySelector(".menuStickyHeader");
        
        if (stickyHeader) {
            const headerHeight = stickyHeader.getBoundingClientRect().height;
            
            const handleScroll = () => {
                if (searchQuery.length === 0 && !isAutoScrolling) {
              categoryRefs.current.forEach((ref, index) => {
                const rect = ref.current.getBoundingClientRect();
                if (rect.top < headerHeight && rect.bottom > headerHeight) {
                    setSelectedCategory(categories[index]);
                    }
              });
            };
        };
    
            window.addEventListener('scroll', handleScroll, { passive: true });
    
            return () => {
              window.removeEventListener('scroll', handleScroll);
            };
        }
    });

    const handleLayoutClick = () => {
     dispatch(setGridLayout(!isGrid))
    }

      const handleCategoryClick = (category, index) => {
        setSelectedCategory(category);
        if (categoryRefs.current[index]) {
            const stickyHeader = document.querySelector(".menuStickyHeader");
            const headerHeight = stickyHeader.getBoundingClientRect().height;
            const elementPosition = categoryRefs.current[index].current.getBoundingClientRect().top; // Element's position relative to the viewport
    const offsetPosition = window.scrollY + elementPosition; // Element's position relative to the whole document
    const scrollToPosition = offsetPosition - headerHeight+1;
    setIsAutoScrolling(true);
    // Calculate the exact position to scroll to
    window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth"
      });
      const checkIfDone = setInterval(() => {
        const tolerance = 1.0; // Small value to account for floating point errors
        const diff = Math.abs(window.scrollY - scrollToPosition);
        
        if (diff <= tolerance) {
            clearInterval(checkIfDone);
            setIsAutoScrolling(false);
        }
    }, 100);
        } else {
          console.error(`Ref at index ${index} is undefined`);
        }
      };;

    return<div className="menuMain">
      { venueState.restaurantLoading ? <div className="loading-indicator centered"></div> : 
      <>
       {(venue.id == null) ? <div>Oops we cant find this restaurant, but check these out</div> : <>
       <Helmet>
       <meta property="og:title" content={venue ? venue.name : ''} />
        <meta property="og:description" content={venue ? venue.description : ''} />
        <meta property="og:image" content={venue && venue.media ? venue.media.logoURL : ''} />
          </Helmet>
       <img src={`${venue.backgroundImageUrl != null ? venue.backgroundImageUrl : "https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2FAC922EA4-ADFE-4EAA-8D54-FE8F90C254EB?alt=media&token=7d36bc4f-4162-4c33-8477-b743881cc900"}`} alt="Background"/>
        <div className="menuMainHeader">
        <h1>{venue.name}</h1>
        </div>
        <div>
        <div className="hstackMimic">
            <p className="secondaryText">{`${venueState.restaurantOpen ? `Open Till ${venueState.restaurantCloseTime}` : "Closed"}`}</p>
  <p className="secondaryText" style={{flexGrow: "1", textAlign: "right"}}>Pickup: 10-15 minutes</p>
  </div>
        
        <div className="hstackMimic">
  <p className="secondaryText" style={{width: "60%",  whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}}>{venue.location.streetAddress}</p>
  <p className="secondaryText" style={{flexGrow: "1", textAlign: "right"}}>Delivery: 20-35 minutes</p>
  </div>
  </div>
  <DeliveryPickupCell venueAddress={venue.location.streetAddress} />
        <div className="menuStickyHeader" ref={menuStickyHeaderRef}>
        <div className="searchHeader">
        <input type="text" className="searchBox" onChange={handleSearch} style={{margin: "0px 10px 0px 0px"}} placeholder="Search..."/>
        <button className="changeLayoutButton" onClick={handleLayoutClick}>
        <span className="material-symbols-rounded" style={{fontSize: "30px", margin: "0", padding: "0"}}>
        {isGrid ? "view_agenda" : "grid_view"}
         </span>
        </button>
        </div>
        {(searchQuery.length < 1) &&
        <MenuCategoryScroll 
        categories={(categories)}
        selectedCategory={selectedCategory}
        handleCategoryClick={handleCategoryClick}
        />
      }
        <div className="dividerHorizontal"></div>
        </div>
        <div>
      {categories.map((category, index) => (
        menu[category]  && menu[category].length > 0 &&
        <div className="categorySectionRef" key={category} ref={categoryRefs.current[index]}>
          <MenuCategorySection category={category} items={menu[category]} isGrid={isGrid} />
        </div>
        
      ))}
      {Array.isArray(cartState.items) && cartState.items.length > 0 && 
      <button className='primaryButton primaryButtonBox' onClick={() => {navigate("/revieworder")}} 
      style={{fontWeight: "550", position: "fixed", bottom: "20px", right: "15px", left: "15px", zIndex: "1000"}} 
      type="button">View cart · R {cartState.total}</button>}
       <div className="menuFooter">Powered by CRWD</div>
    </div>
    </>}
    </>}
    
                        </div>
        } 


        function searchMenu(menu, query) {
          const lowerCaseQuery = query.toLowerCase();
        
          // Loop through each category in the menu
          const filteredMenu = {};
          for (const [category, items] of Object.entries(menu)) {
            const filteredItems = items.filter(item => {
              // Convert name and description to lowercase and check if they include the query
              return item.name.toLowerCase().includes(lowerCaseQuery) ||
                     item.description.toLowerCase().includes(lowerCaseQuery);
            });
        
            if (filteredItems.length > 0) {
              filteredMenu[category] = filteredItems;
            }
          }
        console.log(filteredMenu)
          return filteredMenu;
        }