import { combineReducers } from "redux";
import authReducer from "./authReducer";
import venueReducer from "./venueReducer";
import cartReducer from "./cartReducer";
import restaurantReducer from "./restaurantReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    venue: venueReducer,
    cart: cartReducer,
    restaurant: restaurantReducer,
})

export default rootReducer;