import React from 'react';

const StepperCounter = ({ value, onIncrement, onDecrement, style, showBin }) => {

  const itemQuantityStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "6px 8px",
    backgroundColor: "#f5f5f7",
    borderRadius: "25px",
    ...style
  }

  return (
    <div style={itemQuantityStyle}>
            <span className="material-symbols-rounded" onClick={() => onDecrement()} style={{display: "block", textAlign: "left", margin: "0", padding: "0", fontSize: "24px",fontWeight: "350"}}>
            {showBin ? (value == 1) ? "delete" : "remove" : "remove"}
</span>
<p style={{padding: "0px 20px", margin: "0", fontWeight: "500"}}>{value}</p>
<span className="material-symbols-rounded" onClick={() => onIncrement()} style={{display: "block", textAlign: "right", margin: "0", padding: "0", fontSize: "24px",fontWeight: "350"}}>
add
</span>
</div>
  );
};

export default StepperCounter;
