import React, { forwardRef } from "react";

const MenuCategoryButton = forwardRef((props, ref) => {
    const { text, selected, onClick } = props;
  const buttonStyle = {
    backgroundColor: selected ? "var(--accent-color)" : "#ffffff",
    color: selected ? "white" : "black",
    padding: "8px 12px",
    borderRadius: "8px",
    fontSize: "16px",
    borderWidth: "0px",
    fontWeight: selected ? "600" : "500",
    margin: "0px 0px",
  };

  return (
    <button ref={ref} style={buttonStyle} onClick={onClick}>
      {text}
    </button>
  );
});

export default MenuCategoryButton;