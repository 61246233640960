import React, { useEffect } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, PaymentElement} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CheckoutDeliveryToggle from './Components/CheckoutDeliveryToggle';
import { setIsDelivery } from '../../Redux/Actions/authActions';

const stripePromise = loadStripe('pk_test_51NQlvJJUTIEvfN0jZxaDiptzEoRJUw0PyfivWJBzk2MBx6FT9xxlhUAcqlssbcrMXqbMPeOIaCnYRfXk78ZLr4IL00PkkTVWbw');

const CheckoutMain = () => {
  const cartState = useSelector((state) => state.cart)
  const authState = useSelector(state => state.auth);
  const address = authState.selectedAddress;
  const isDelivery = authState.isDelivery;
  const dispatch = useDispatch();
  const stripeOptions = {
    clientSecret: "pi_3NyCgNJUTIEvfN0j1luiGWdt_secret_nCMcPorCUlRVpfkYzBahaJzzd",
    appearance: {
      theme: "flat",
      variables: {
        colorPrimary: '#5DBCD7',
        colorBackground: '#f5f5f7',
      }
    },
  };
  const items = cartState.items;
    const navigate = useNavigate();

    const toggleOption = () => {
      dispatch(setIsDelivery(!isDelivery))
    }

    const goBack = () => {
        navigate("/revieworder")
    }

  const headerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "15px 15px"
  }

  const textHeaderStyle = {
    textAlign: "center",
    flexGrow: "1",
    fontSize: "16px",
    fontWeight: "550",
    margin: "0px 20px 0px 0px"
  }

  const buttonContainerStyle = {
    position: "fixed",
    bottom: "20px",
    width: "100%",
    zIndex: "1000"
  }

  const cartItemsContainerStyle = {
    margin: "15px 15px",
    padding: "0px 15px",
    backgroundColor: "#f1f1f1",
    borderRadius: "10px"
  }

  const cartItemCellStyle = {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    justifyContent: "flex-start",
    padding: "15px 0px",
  }

  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
    <div style={{alignItems: "center", textAlign: "center"}} className="reviewOrder">
        <div style={headerStyle}>
        <span onClick={goBack} className="material-symbols-rounded" style={{display: "block", textAlign: "left", margin: "0", padding: "0", fontSize: "30px"}}>
chevron_left
</span>
            <h5 style={textHeaderStyle}>Checkout</h5>
            </div>
            <div className="dividerHorizontal"></div>
            <CheckoutDeliveryToggle toggleOption={toggleOption} isDelivery={isDelivery} />
            <div>
            <p style={{textAlign: "left", padding: "0", margin:"10px 0px 5px 15px", fontWeight: "550", fontSize: "14px"}}>Delivering To</p>
              <p style={{textAlign: "left", padding: "0", margin:"0px 10px 0px 15px", fontWeight: "400", fontSize: "14px", color:"black", flexGrow: "1", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}} className="secondaryText">{address.streetAddress}</p>
            </div>
      <div style={cartItemsContainerStyle}>
        {items.map((item, index) => (
          <div key={index} style={{...cartItemCellStyle}}>
            <div style={{padding: "0px", margin: "0px", width: "25px"}}>
            <p style={{fontSize: "16px",fontWeight: "500", margin: "0px", padding: "0px"}}>{item.quantity}</p>
            </div>
          <p style={{fontSize: "16px",fontWeight: "550", margin: "0px", flexGrow: "1"}}>{item.name}</p>
          <p style={{fontSize: "16px",fontWeight: "500", margin: "0"}}>R {item.price}</p>
      </div>
          ))}
      </div>
      <div style={cartItemsContainerStyle}>
      <div style={{...cartItemCellStyle}}>
       <p style={{fontSize: "18px",fontWeight: "550", margin: "0", flexGrow: "1"}}>Total</p>
       <p style={{fontSize: "16px",fontWeight: "550", margin: "0"}}>R {cartState.total}</p>
      </div>
      </div>
      <div style={{margin: "0px 15px"}}>
      <PaymentElement options={{layout: "tabs", defaultValues: {billingDetails: {address:{country: "usa"}}}, }} />
      </div>
      <button className='primaryButton primaryButtonBox' style={{margin: "30px 0px 30px 0px"}} type="button">Pay Now</button>
    </div>
    </Elements>
  );
};

export default CheckoutMain;