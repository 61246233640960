export const CHANGE_CART_RESTAURANT = "CHANGE_CART_RESTAURANT";
export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_ITEM_QUANTITY = "UPDATE_ITEM_QUANTITY";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";

const changeCartRestaurant = (restaurant) => ({
        type: CHANGE_CART_RESTAURANT,
        payload: {id: restaurant.id, name: restaurant.name, urlPath: restaurant.urlPath},
});

const addToCart = (item) => ({
        type: ADD_TO_CART,
        payload: item,
});

const updateItemQuantity = (itemId, quantity) => ({
        type: UPDATE_ITEM_QUANTITY,
        payload: { itemId, quantity },
});

const removeFromCart = (itemId) => ({
        type: REMOVE_FROM_CART,
        payload: itemId,
});


export { changeCartRestaurant, addToCart, updateItemQuantity, removeFromCart };