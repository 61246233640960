import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../Services/AuthServices';
import { AuthContext } from '../../Helpers/Contexts/AuthProvider';
import { authInstance } from '../../index';
import { RecaptchaVerifier } from 'firebase/auth';
import { useSelector } from 'react-redux';

const AuthMain = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const { setConfirmationResult } = useContext(AuthContext);
    const state = useSelector((state) => state.auth);
    const countryCode = state.countryCode;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const authService = new AuthService();

    const showCountryCode = () => navigate("/countrycode");

    useEffect(() => {
        if (!window.recaptchaVerifier) {
            console.log("no recaptcha");
        window.recaptchaVerifier = new RecaptchaVerifier(authInstance, 'recaptchaContainer', {
            'size': 'invisible',
            'callback': async (response) => {
                console.log("callback Success");
            },
            'expired-callback': () => {
                console.log("expired");
            },
            'error-callback': (error) => {
                console.log("error");
                console.log(error);
            }
        });
    }

    return () => {
        if (window.recaptchaVerifier) {
            window.recaptchaVerifier.clear();
            window.recaptchaVerifier = null;
            if (window.grecaptcha && window.recaptchaWidgetId !== undefined) {
                window.grecaptcha.reset(window.recaptchaWidgetId);
            }
        }
    };
}, []); 

    const sendCode = async () => {
        setLoading(true);
        console.log("Send Code", window.recaptchaVerifier)
    window.recaptchaVerifier.verify()
    console.log("Verify")
        try {
            const result = await authService.sendVerificationCode(countryCode + phoneNumber, window.recaptchaVerifier);
            setConfirmationResult(result);
            setLoading(false);
            navigate("/verify");
            if (window.recaptchaVerifier) {
                window.recaptchaVerifier.clear();
                window.recaptchaVerifier = null;
                if (window.grecaptcha && window.recaptchaWidgetId !== undefined) {
                    window.grecaptcha.reset(window.recaptchaWidgetId);
                }
            }
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };
    //TODO: add handler for error and loading of sending code 
  //  {loading && <p>Loading...</p>}

    const goBack = () => navigate(-1);

    const handleInputChange = (event) => setPhoneNumber(event.target.value);

    return (
        <div className='recaptchaParent' style={styles.container}>
            <span onClick={goBack} className="material-symbols-rounded" style={{display: "block", textAlign: "left", margin: "0", padding: "0", fontSize: "30px"}}>
chevron_left
</span>
            <h2>Enter your mobile number</h2>
            <label style={styles.label}>We will send you an SMS with a verification code</label>
            <div style={styles.inputGroup}>
                <span style={styles.countryCode} onClick={showCountryCode}>{countryCode}</span>
                <input 
                    type="tel" 
                    value={phoneNumber}
                    onChange={handleInputChange}
                    placeholder="Mobile Number"
                    style={styles.input}
                />
            </div>
            {error && <p className='errorText'>{error}</p>}
            <div></div>
            <button  id="recaptchaContainer" onClick={sendCode} style={styles.continueButton}>
      {loading ? <div className="loading-indicator"></div> : "Continue"}
    </button>
            <p style={styles.disclaimer}>By proceeding, you consent to get calls, WhatsApp or SMS messages, from CRWD and its partners to the number provided.</p>
        </div>
    );
}

const styles = {
    container: {
        width: '90%',
        maxWidth: '400px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: '#fff',
    },
    disclaimer: {
        fontSize: '12px',
        fontWeight: 'normal',
        textAlign: 'left',
        color: 'rgb(161, 161, 161)'
    },
    label: {
        display: 'block',
        marginBottom: '10px',
        fontSize: '16px',
    },
    inputGroup: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
    },
    countryCode: {
        marginRight: '10px',
        backgroundColor: '#f0f0f0',
        padding: '12px 10px',
        borderRadius: '8px',
        border: '0px solid #ccc',
    },
    input: {
        flex: 1,
        padding: '12px 10px',
        fontSize: '16px',
        backgroundColor: '#f0f0f0',
        borderRadius: '8px',
        border: '0px solid #ccc',
        outline: 'none',
    },
    continueButton: {
        width: '100%',
        padding: '15px',
        fontSize: '16px',
        fontWeight: '550',
        backgroundColor: '#5DBCD7',
        color: '#fff',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
    }
}

export default AuthMain;
