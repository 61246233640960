import React, { useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import StepperCounter from "../../Helpers/Components/StepperCounter";
import { addToCart } from "../../../Redux/Actions/cartActions";

const MenuItemDetailView = () => {
    const [quantity, setQuantity] = useState(1);
    const state = useSelector((state) => state.venue);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { itemID } = useParams();
    const menu = state.menu;

    const [currentItem, setCurrentItem] = useState(null);

    const addItem = () => {
        dispatch(addToCart({...currentItem, quantity}))
        goBack()
    }

    const goBack = () => {
        navigate(-1)
    }

    const decrementQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
        }
    }

    useEffect(() => {
      if (itemID && menu) {
        const flatMenuArray = Object.values(menu).flat();
        const item = flatMenuArray.find(item => item.id === itemID);
        if (item) {
          setCurrentItem(item);
        } else {
            
        }
      }
    }, [itemID, menu]);
  
  const itemContainerStyle = {
    display: "flex",
    width: `100%`,
    flexDirection: "column",
    alignItems: "center",
    margin: "0px 0px",
    justifyContent: "flex-start",
    padding: "0px 0px",
  };

  const menuItemImageStyle = {
    width: `100%`,
    height: `60vw`,
    alignSelf: "center",
    margin: "0px",
    objectFit: "cover",
  };

  const textStyle = {
    fontSize: "18px",
    fontWeight: "550",
    textAlign: "left",
    margin: "0px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    lineClamp: "2",
    WebkitBoxOrient: "vertical",
  };

  const descriptionStyle = {
    fontSize: "12px",
    fontWeight: "400",
    color: "gray",
    flexGrow: "0",
    textAlign: "left",
    margin: "5px 0px 0px 0px",
  };

  const menuItemDescriptionStyle = {
    flex: "1",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "15px 15px 10px 15px",
    boxSizing: "border-box",
    margin: "0px 0px",
    alignItems: "flex-start",
    textAlign: "left",
  };

  const backButtonStyle = {
    position: "fixed",
    top: "15px",
    left: "15px",
    display: "inline-block",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    textAlign: "center",
    lineHeight: "40px",  
    fontSize: "26px",
    cursor: "pointer",
    transition: "background-color 0.3s"
};

  const menuItemPriceStyle = {
    fontSize: "14px",
    fontWeight: "500",
    flexAlign: "bottom",
    textAlign: "left",
    flexGrow: "1",
    margin: "0px",
    paddingTop: "15px",
  };

  return (
    currentItem ? (
      <div style={itemContainerStyle}>
        <span onClick={goBack} className="material-symbols-rounded" style={{...backButtonStyle, position: "fixed"}}>
        chevron_left
</span>
        {currentItem.imageUrl != null ? (
          <img src={currentItem.imageUrl} style={menuItemImageStyle} alt="menuitem" />
        ) : (<div style={{height: "55px"}}></div>)}
        <div style={menuItemDescriptionStyle}>
          <p style={textStyle}>{currentItem.name}</p>
          <p className="truncate-text" style={descriptionStyle}>{currentItem.description}</p>
          <p style={menuItemPriceStyle}>{`R ${currentItem.price}`}</p>
          <StepperCounter style={{margin: "30px 0px 0px 0px"}}
           value={quantity}
           onIncrement={() => setQuantity(quantity + 1)}
           onDecrement={decrementQuantity}
            setValue={setQuantity} ></StepperCounter>
          <button className='primaryButton primaryButtonBox' onClick={addItem} style={{fontWeight: "550", position: "fixed", bottom: "20px", left: "15px", right: "15px"}} type="button">Add {quantity} to cart · R {currentItem.price*quantity}</button>
        </div>
      </div>
    ) : (
      <div className="loading-indicator centered"></div>
    )
  );
};

export default MenuItemDetailView;