import React from "react";
import MenuHorizontalCellView from "./MenuHorizontalCellView";
import MenuVerticalCellView from "./MenuVerticalCellView";


const MenuCategorySection = ({category, items, isGrid}) => {

    const listCellStyle = {
        border: "0",
        borderRadius: "18px",
        background: "#f5f5f7",
        margin: "10px 15px",
        width: "calc(100% - 30px)",
        padding: "1px 0px",
        boxSizing: "border-box",
      }
      const gridCellStyle = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridAutoFlow: "row",
        gap: "15px",
        border: "0",
        borderRadius: "18px",
        background: "#f5f5f7",
        margin: "10px 15px",
        width: "calc(100% - 30px)",
        padding: "15px 15px",
        boxSizing: "border-box",
        aliignItems: "end",
      }

  return (
    <div>
        <h5 className="categoryHeader">{category}</h5>
    <div style={isGrid ? gridCellStyle : listCellStyle}>
    {items.map((item, index) => (
        isGrid ? 
        <MenuVerticalCellView 
    key={index}
    item={item}
/>
     : 
        <MenuHorizontalCellView 
        key={index}
        item={item}
    />
      ))}
                </div>
                </div>
  );
};

export default MenuCategorySection;