import { firestoreInstance } from "../index";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { setMenuItems, setRestaurant, setRestaurantCloseTime, setRestaurantDistance, setRestaurantLoading, setRestaurantOpen } from "../Redux/Actions/restaurantActions";
class RestaurantService {

    async fetchRestaurant(path, dispatch) {
        dispatch(setRestaurantLoading(true));
    
        try {
            const restaurant = await this.getRestaurantByPath(path);
            if (restaurant) {
                console.log("Restaurant found:", restaurant);
                dispatch(setRestaurant(restaurant));
                await this.fetchMenu(restaurant.id, dispatch);

                const openStatus = isRestaurantOpen(restaurant.businessHours);
                dispatch(setRestaurantOpen(openStatus.isOpen));
                dispatch(setRestaurantCloseTime(openStatus.closeTime));
            } else {
                console.log("No Restaurant Found");
                dispatch(setRestaurant({}));
            }
        } catch (error) {
            console.error("Error fetching restaurant:", error);
        } finally {
            dispatch(setRestaurantLoading(false));
        }
    }

    async getRestaurantByPath(path) {
        try {
            const restaurantRef = collection(firestoreInstance, "Restaurants");
            const lowercasedPath = path.toLowerCase();
            const q = query(restaurantRef, where("urlPath", "==", lowercasedPath));
            const snapshot = await getDocs(q);
            return !snapshot.empty ? snapshot.docs[0].data() : null;
        } catch (error) {
            console.error("Error getting restaurant by path:", error);
            return null;
        }
    }

    async fetchMenu(restaurantID, dispatch) {
        try {
            const restaurantRef = doc(firestoreInstance, "Restaurants", restaurantID);
            const menuRef = collection(restaurantRef, "Menu");
            const menuSnapshot = await getDocs(menuRef);

            if (!menuSnapshot.empty) {
                const config = menuSnapshot.docs.find(doc => doc.id === "menuConfig")?.data();
                const configCategories = config?.categories || [];
                const menuItems = menuSnapshot.docs
                    .filter(doc => doc.id !== "menuConfig")
                    .map(doc => ({ id: doc.id, ...doc.data() }));
                
                const menuItemsById = menuItems.reduce((acc, item) => {
                    acc[item.id] = item;
                    return acc;
                }, {});

                const organizedMenu = configCategories.reduce((acc, categoryMap) => {
                    const categoryName = categoryMap.name;
                    const menuItemIDs = categoryMap.menuItemIDs || [];
                    acc[categoryName] = menuItemIDs.map(id => menuItemsById[id]).filter(Boolean);
                    return acc;
                }, {});

                console.log("Organized menu:", organizedMenu);
                dispatch(setMenuItems(organizedMenu));
            } else {
                console.log("No Menu Items Found");
            }
        } catch (error) {
            console.error("Error fetching menu:", error);
        }
    }

    async checkDistance(lat1, lng1, lat2, lng2, dispatch) {
        try {
            function toRad(x) {
                return (x * Math.PI) / 180;
            }

            const R = 6371; // Radius of Earth in kilometers
            const dLat = toRad(lat2 - lat1);
            const dLon = toRad(lng2 - lng1);
            const latt1 = toRad(lat1);
            const latt2 = toRad(lat2);

            const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(latt1) * Math.cos(latt2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const distance = (R * c);
            const distanceString = `${distance.toFixed(2)} KM`;

            const payload = { distanceInt: distance, distanceString };
            dispatch(setRestaurantDistance(payload));
            console.log("Calculated distance:", payload);
        } catch (error) {
            console.error("Error calculating distance:", error);
        }
    }
}

function isRestaurantOpen(businessHours) {
    const currentDate = new Date();
    const dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const currentDay = dayNames[currentDate.getDay()];
    const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes(); // Convert current time to minutes since midnight

    const todayHours = businessHours[currentDay];

    if (!todayHours) {
        return { isOpen: false, closeTime: null };
    }

    const openingTime = parseTime(todayHours.open);
    const closingTime = parseTime(todayHours.close);
    const closingSoonTime = closingTime - 60; // 1 hour before closing time

    let isOpen = false;
    let status = "Closed";
    if (currentTime >= openingTime && currentTime <= closingTime) {
        isOpen = true;
        if (currentTime >= closingSoonTime) {
            status = "Closing Soon";
        } else {
            status = "Open";
        }
    }

    return { isOpen, closeTime: todayHours.close, status };
}

function parseTime(timeStr) {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 + minutes;
}

export default RestaurantService;