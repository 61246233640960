import React, { useState } from 'react';
import CountryCodes from '../../Helpers/ConstantData/CountryCodes.json';
import { useNavigate } from 'react-router-dom';
import { setCountryCode } from '../../Redux/Actions/authActions';
import { useDispatch } from 'react-redux';

const CountryCodeSelector = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const goBack = () => {
        navigate(-1)
    }

    const handleCountryClick = (country) => {
          dispatch(setCountryCode(country.dial_code))
          goBack()
    }
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredCountries = CountryCodes.filter(country => 
        country.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div style={styles.container}>
            <div style={styles.header}>
            <span onClick={goBack} className="material-symbols-rounded" style={{display: "block", textAlign: "left", margin: "0", padding: "0", fontSize: "30px"}}>
chevron_left
</span>
                <h2>Country/Region</h2>
            </div>
            <input 
                style={styles.searchInput}
                type="text" 
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
            />
            <ul style={styles.countryList}>
                {filteredCountries.map((country) => (
                    <li key={country.code} style={styles.countryItem} onClick={() => handleCountryClick(country)}>
                        {country.name} <span style={styles.countryCode}>{country.dial_code}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

const styles = {
    container: {
        backgroundColor: '#fff',
        padding: '20px',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    closeButton: {
        fontSize: '24px',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    searchInput: {
        width: '100%',
        padding: '10px',
        marginTop: '20px',
        marginBottom: '10px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        fontSize: '16px',
    },
    countryList: {
        listStyleType: 'none',
        padding: '0',
    },
    countryItem: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #eee',
        padding: '10px 0',
    },
    countryCode: {
        fontWeight: 'bold',
    }
}

export default CountryCodeSelector;
