import React, { useEffect, useState } from 'react';
import AddressSearch from './Components/AddressSearch';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MapView from './Components/MapView';
import { changeAddress } from '../../Redux/Actions/authActions';
import { LoadScriptNext } from '@react-google-maps/api';
const libraries = ['places'];
function CreateAddress() {
    const [searchHidden, setSearchHidden] = useState(false);
    const [details, setDetails] = useState({unitNumber: "", buildingName: "", instructions: "", label: ""});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const state = useSelector((state) => state.auth);
    const address = state.selectedAddress;
    const lat = Number(address?.latitude) || -26.0968862;
    const lng = Number(address?.longitude) || 28.0527794;  
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    const closePage = () => {
      navigate(-1);
    };

    const saveContinue = () => {
      dispatch(
          changeAddress({
              ...address,
              unitNumber: details.unitNumber,
              name: details.buildingName,
              instructions: details.instructions,
              label: details.label,
          })
      );
      closePage();
  };

    useEffect(() => {
        if (address) {
        setSearchHidden(true);
            let updatedDetails = {...details};
              updatedDetails.unitNumber = address.unitNumber || "";
              updatedDetails.buildingName = address.buildingName || "";
              updatedDetails.label = address.label || "";
              updatedDetails.instructions = address.instructions || "";
              updatedDetails.buildingName = address.name || "";
            setDetails(updatedDetails);
        }else{
            setSearchHidden(false);
        }// eslint-disable-next-line
    }, [address])

  return (
    <LoadScriptNext googleMapsApiKey={apiKey} libraries={libraries} preventGoogleFontsLoading={true}>
    <div style={{textAlign: "center", alignContent: "center", alignItems: "center"}}>
    <span className="material-symbols-rounded" onClick={closePage} style={{display: "block", textAlign: "left", margin: "15px"}}>
close
</span>
        <h2  style={{textAlign: "left", margin: "15px 15px"}}>Deliver to</h2>
        <MapView lat={lat} lng={lng} height={address == null ? "0px" : "180px"} />
        <div className={searchHidden ? "hidden" : ""} style={{margin: "0px 0px 15px 0px"}}>
        <p style={{textAlign: "left", padding: "0", margin:"0px 0px 5px 15px", fontWeight: "500", fontSize: "12px"}}>Delivering To</p>
         <AddressSearch />
         </div>
         { (address) ?
         <>
        <div className={searchHidden ? "hstackMimic" : "hidden hstackMimic"} style={{margin: "10px 15px 10px 15px"}}>
        <p style={{textAlign: "left", padding: "0", margin:"0px 10px 0px 0px", fontWeight: "400", fontSize: "14px", color:"black", flexGrow: "1", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}} className="secondaryText">{address?.fullAddressString}</p>
            <button className="secondaryButtonStyle" onClick={() => setSearchHidden(false)}>Edit</button>
            </div>
          <input value={details.buildingName}
           className='searchBox' 
           placeholder="Building or complex name"
           onChange={(e) => setDetails({...details, buildingName: e.target.value})}
           style={{margin: "5px 0px 5px 0px"}}
            />
            <input value={details.unitNumber}
           className='searchBox'
            placeholder="Apt, flat or floor number"
            onChange={(e) => setDetails({...details, unitNumber: e.target.value})}
            style={{margin: "5px 0px 5px 0px"}}
             />
        <p style={{textAlign: "left", padding: "0", margin:"0px 0px 5px 15px", fontWeight: "600", fontSize: "18px"}}>Delivery Options</p>
        <div className="instruction">
          <input value={details.instructions}
           className='searchBox' 
           placeholder="Instructions for delivery person"
           onChange={(e) => setDetails({...details, instructions: e.target.value})}
           style={{margin: "5px 0px 5px 0px"}}
            />
        </div>
        <input value={details.label}
         className='searchBox' 
         placeholder="Address label"
         onChange={(e) => setDetails({...details, label: e.target.value})}
         style={{margin: "5px 0px 5px 0px"}}
          />
        <button className='primaryButton primaryButtonBox' style={{margin: "20px 0px 0px 0px"}} onClick={saveContinue}>Save and continue</button>
        </>
         : "" }
    </div>
    </LoadScriptNext>
  );
};

export default CreateAddress;
