export const SET_RESTAURANT = "SET_RESTAURANT";
export const SET_RESTAURANT_ID = "SET_RESTAURANT_ID";
export const SET_RESTAURANT_LOADING = "SET_RESTAURANT_LOADING";
export const SET_RESTAURANT_DISTANCE = "SET_RESTAURANT_DISTANCE";
export const SET_MENU_ITEMS = "SET_MENU_ITEMS";
export const SET_RESTAURANT_OPEN = "SET_RESTAURANT_OPEN";
export const SET_RESTAURANT_CLOSE_TIME = "SET_RESTAURANT_CLOSE_TIME";

const setRestaurant = (restaurant) => ({
        type: SET_RESTAURANT,
        payload: restaurant,
});

const setRestaurantID = (restaurantID) => ({
    type: SET_RESTAURANT_ID,
    payload: restaurantID,
});

const setRestaurantLoading = (bool) => ({
    type: SET_RESTAURANT_LOADING,
    payload: bool,
});

const setRestaurantDistance = (distance) => ({
    type: SET_RESTAURANT_DISTANCE,
    payload: distance,
});

const setMenuItems = (menu) => ({
    type: SET_MENU_ITEMS,
    payload: menu,
});

const setRestaurantOpen = (bool) => ({
    type: SET_RESTAURANT_OPEN,
    payload: bool,
});

const setRestaurantCloseTime = (time) => ({
    type: SET_RESTAURANT_CLOSE_TIME,
    payload: time,
});


export { setRestaurant, setRestaurantID, setRestaurantLoading, setRestaurantDistance, setMenuItems, setRestaurantOpen, setRestaurantCloseTime };