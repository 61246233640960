import { SET_MENU_ITEMS, SET_VENUE, SET_VENUE_CLOSE_TIME, SET_VENUE_DISTANCE, SET_VENUE_ID, SET_VENUE_LOADING, SET_VENUE_OPEN } from "../Actions/venueActions";

const initialState = {
    venueID: "",
    venue: {},
    venueLoading: false,
    venueOpen: false,
    venueCloseTime: null,
    distanceInt: null,
    distanceString: null,
    menu: {},
};

const venueReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VENUE_ID:
            return {
                ...state,
                venueID: action.payload,
            };
            case SET_VENUE:
            return {
                ...state,
                venue: action.payload,
                venueID: action.payload.id || "",
            };
            case SET_VENUE_LOADING:
            return {
                ...state,
                venueLoading: action.payload,
            };
            case SET_VENUE_DISTANCE:
                return {
                    ...state,
                    distanceInt: action.payload.distanceInt,
                    distanceString: action.payload.distanceString,
                };
            case SET_MENU_ITEMS:
                return {
                    ...state,
                    menu: action.payload,
                };
            case SET_VENUE_OPEN:
                return {
                    ...state,
                    venueOpen: action.payload,
                };
            case SET_VENUE_CLOSE_TIME:
                return {
                    ...state,
                    venueCloseTime: action.payload,
                };
        default:
             return state
    }
};

export default venueReducer